
















import { defineComponent, onBeforeMount, watch } from '@vue/composition-api'
import { useActions, useGetters, useMutations, useState } from '@u3u/vue-hooks'

import MySidenav from '@/components/my/Sidenav.vue'
import UnderMaintenance from '@/views/UnderMaintenance.vue'

import auth from '@/composables/auth'

export default defineComponent({
  name: 'my-commune',
  components: {
    MySidenav,
    UnderMaintenance,
  },
  setup(_props) {
    const { fetchI18n } = useActions('my', ['fetchI18n'])
    const { FETCH_I18N } = useActions('commune', ['FETCH_I18N'])
    const faqActions = useActions('faq', ['FETCH_I18N'])
    const { i18n } = useState('commune', ['i18n'])
    const { currentLang } = useGetters(['currentLang'])
    const mutations = {
      ...useMutations('my', ['START_LOADING', 'STOP_LOADING']),
    }

    // Empeche le loading 2x des datas
    onBeforeMount(() => {
      mutations.START_LOADING()
      console.log('Mounting MyCommune')
      fetchI18n()
      FETCH_I18N()
      faqActions.FETCH_I18N()
    })

    watch(currentLang, () => {
      fetchI18n()
      FETCH_I18N()
      faqActions.FETCH_I18N()
      mutations.STOP_LOADING()
    })

    return {
      isLogged: auth.isLogged,
      ...useGetters(['content']),
      ...useGetters('my', ['isLoading']),
      ...useGetters('user', ['isCommune']),
      ...useGetters(['chrome']),
      i18n,
    }
  },
})
